import React, { useEffect, useState } from 'react';
import { fetchData, postData, deleteData, putData } from '../../../redux/apiUtils';
import './AttributesManager.scss';
import SearchableDropdown from './SearchableDropdown';

const attributeTypes = [
    { id: '1', name: 'List' },
    { id: '2', name: 'Dropdown' },
    { id: '3', name: 'Tags' },
    // Add more types as needed
];

const AttributesManager = () => {
    const [attributes, setAttributes] = useState([]);
    const [filteredAttributes, setFilteredAttributes] = useState([]); // Filtered attributes for search
    const [products, setProducts] = useState([]);
    const [newAttribute, setNewAttribute] = useState({
        id: '',  
        attribute_name: '',
        attribute_type: '',
        attribute_values: [],
        product_id: ''
    });
    const [editAttribute, setEditAttribute] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState(''); // Search term
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const [itemsPerPage] = useState(5); // Items per page for pagination

    useEffect(() => {
        fetchAttributes();
        fetchProducts();
    }, []);

    useEffect(() => {
        handleSearch(); // Apply search filter whenever searchTerm or attributes change
    }, [searchTerm, attributes]);

    const fetchAttributes = async () => {
        setLoading(true);
        const response = await fetchData('attributes');
        if (response) {
            setAttributes(response);
            setFilteredAttributes(response); // Initially, all attributes are visible
        }
        setLoading(false);
    };

    const fetchProducts = async () => {
        const response = await fetchData('products');
        if (response) {
            setProducts(response);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAttribute({
            ...newAttribute,
            [name]: value,
        });
    };

    const handleValuesChange = (e) => {
        const { value } = e.target;
        setNewAttribute((prev) => ({
            ...prev,
            attribute_values: value.split(',').map(v => v.trim()),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (isEditing) {
            await putData(`attributes/${editAttribute.id}`, newAttribute);
            setMessage('Attribute updated successfully!');
            setIsEditing(false);
            setEditAttribute(null);
        } else {
            await postData('attributes', newAttribute);
            setMessage('Attribute added successfully!');
        }
        setNewAttribute({ id: '', attribute_name: '', attribute_type: '', attribute_values: [], product_id: '' });
        fetchAttributes();
    };

    const handleEdit = (attribute) => {
        setNewAttribute(attribute);
        setEditAttribute(attribute);
        setIsEditing(true);
    };

    const handleDelete = async (id) => {
        setLoading(true);
        await deleteData(`attributes`, id);
        setMessage('Attribute deleted successfully!');
        fetchAttributes();
    };

    const handleProductChange = (id) => {
        setNewAttribute(prev => ({
            ...prev,
            product_id: id
        }));
    };

    const handleSearch = () => {
        const filtered = attributes.filter(attribute => {
            // Find the corresponding product name
            const product = products.find(product => product.id === attribute.product_id);
            const productName = product ? product.name : '';
    
            // Check if either the attribute name or product name matches the search term
            return (
                attribute.attribute_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                productName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
    
        setFilteredAttributes(filtered);
        setCurrentPage(1); // Reset to the first page after a search
    };
    

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate displayed attributes for pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentAttributes = filteredAttributes.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredAttributes.length / itemsPerPage);

    return (
        <div className="attributes-manager-container">
            <h1>Attributes Manager</h1>

            {message && <div className="message">{message}</div>}

            
            <div className="attribute-form-card">
                <form onSubmit={handleSubmit}>
                    <label htmlFor="attribute_name">Attribute Name</label>
                    <input
                        type="text"
                        name="attribute_name"
                        value={newAttribute.attribute_name}
                        onChange={handleInputChange}
                        placeholder="Attribute Name"
                        required
                    />

                    <label htmlFor="attribute_type">Attribute Type</label>
                    <div className="dropdown-container">
                        <select
                            className="custom-dropdown"
                            name="attribute_type"
                            value={newAttribute.attribute_type}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Attribute Type</option>
                            {attributeTypes.map(type => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <label htmlFor="attribute_values">Attribute Values</label>
                    <input
                        type="text"
                        value={newAttribute.attribute_values.join(', ')}
                        onChange={handleValuesChange}
                        placeholder="Comma-separated values"
                    />

                    <label htmlFor="product_id">Select Product</label>
                    <SearchableDropdown
                        options={products}
                        value={newAttribute.product_id}
                        onChange={handleProductChange}
                        placeholder="Select a product"
                    />
                    <button type="submit" className="submit-btn">
                        {isEditing ? 'Update Attribute' : 'Add Attribute'}
                    </button>
                </form>
            </div>

            {loading ? (
                <div className="loading-spinner">Loading...</div>
            ) : (
                <>
                {/* Search bar */}
                <input
                    type="text"
                    placeholder="Search attributes/product name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-bar"
                />

                    <ul className="attributes-list">
                        {currentAttributes.map(attribute => {
                            const filteredProduct = products.filter(product => product.id === attribute.product_id);
                            const productName = filteredProduct.length > 0 ? filteredProduct[0].name : "Product not found";

                            return (
                                <li key={attribute.id} className="attribute-card">
                                    <div className="attribute-details">
                                        <p className="attribute-name">Product Name: {productName}</p>
                                        <p className="attribute-type"><b>ID:</b> {attribute.product_id}</p>
                                        <p className="attribute-type"><b>Name:</b> {attribute.attribute_name}</p>
                                        <p className="attribute-type"><b>Type:</b> {attribute.attribute_type}</p>
                                        <div className="attribute-values">
                                            <b>Values:</b> {attribute.attribute_values.join(', ')}
                                        </div>
                                    </div>
                                    <div className="actions">
                                        <button onClick={() => handleEdit(attribute)} className="edit-btn">Edit</button>
                                        <button onClick={() => handleDelete(attribute.id)} className="delete-btn">Delete</button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>

                    {/* Pagination controls */}
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                className={`page-btn ${currentPage === index + 1 ? 'active' : ''}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>

                </>
            )}
        </div>
    );
};

export default AttributesManager;
