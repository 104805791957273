import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Breadcrumb from './Breadcrumb';
import { useCart } from '../context/CartContext';
import useGetData from '../hooks/useGetData';

import { useNavigate } from 'react-router-dom'; // Import useHistory for redirection
import toast from 'react-hot-toast';

function Checkout() {
  const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    address: '',
    town_city: '',
    country: '',
    postcode_zip: '',
    mobile: '',
    email_address: '',
    order_notes: '',
  });

  const navigate = useNavigate();
  const { cartItems, getCartTotal,clearCart } = useCart();
  const { postData } = useGetData('orders');
  const { data: previousAddress,refetch } = useGetData(`orders/previous-address`);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validation: Check if all required fields are filled out
    const requiredFields = [
      formData.first_name,
      formData.last_name,
      formData.address,
      formData.town_city,
      formData.country,
      formData.postcode_zip,
      formData.mobile,
      formData.email_address
    ];
  
    const allFieldsFilled = requiredFields.every(field => field.trim() !== '');
  
    if (!allFieldsFilled) {
      toast.error('Please fill out all required fields.');
      return;
    }
  
    // Additional validation for email and mobile (if needed)
    if (!/\S+@\S+\.\S+/.test(formData.email_address)) {
      toast.error('Please enter a valid email address.');
      return;
    }
  
    if (!/^\d{10,15}$/.test(formData.mobile)) { // Adjust regex as needed
      toast.error('Please enter a valid mobile number.');
      return;
    }
  
    // Validation: Check if there is at least one item in the cart
    if (cartItems.length === 0) {
      toast.error('Your cart is empty. Please add at least one product.');
      return;
    }
  
    const orderData = {
      user_id: 'userID', // Replace with actual user ID
      first_name: formData.first_name,
      last_name: formData.last_name,
      // company_name: formData.company_name,
      address: formData.address,
      town_city: formData.town_city,
      country: formData.country,
      postcode_zip: formData.postcode_zip,
      mobile: formData.mobile,
      email_address: formData.email_address,
      order_notes: formData.order_notes,
      cart_items: cartItems.map(({ id, ...rest }) => ({ ...rest })),
      total: getCartTotal(),
      shipping: e.target.shipping.value // Assuming you have a way to get the selected shipping option
    };
  
    try {
      const response = await postData(orderData);
      // console.log(response);
      clearCart();
      toast.success('Order placed successfully '+response.data.order_id);
      navigate('/orderStatus');
    } catch (error) {
      console.error('Error placing order:', error);
      toast.error('Failed to place order. Please try again.');
    }
  };
  

  useEffect(() => {
    if (previousAddress) {
      setFormData({
        first_name: previousAddress.first_name || '',
        last_name: previousAddress.last_name || '',
        company_name: previousAddress.company_name || '',
        address: previousAddress.address || '',
        town_city: previousAddress.town_city || '',
        country: previousAddress.country || '',
        postcode_zip: previousAddress.postcode_zip || '',
        mobile: previousAddress.mobile || '',
        email_address: previousAddress.email_address || '',
        order_notes: previousAddress.order_notes || '',
      });
      toast.success('Previous address loaded successfully.');
    }
  }, [previousAddress]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleShipToDifferentAddressChange = (e) => {
    const isChecked = e.target.checked;
    setShipToDifferentAddress(isChecked);
    if (isChecked) {
      setFormData((prevState) => ({
        ...prevState,
        address: '',
        town_city: '',
        country: '',
        postcode_zip: '',
      }));
    }else{
      refetch();
    }
  };
  return (
    <>
      <Navbar />
      <Breadcrumb title="Checkout" />
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1 className="mb-4">Billing details</h1>
          <form onSubmit={handleSubmit}>
            <div className="row g-5">
              <div className="col-md-12 col-lg-6 col-xl-7">
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                  <div className="form-check my-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Address-1"
                        name="Address"
                        value="Address"
                        checked={shipToDifferentAddress}
                        onChange={handleShipToDifferentAddressChange}
                      />
                      <label className="form-check-label" htmlFor="Address-1">Ship to a different address?</label>
                    </div>
                    <div className="form-item w-100">
                      <label className="form-label my-3">First Name<sup>*</sup></label>
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="form-item w-100">
                      <label className="form-label my-3">Last Name<sup>*</sup></label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="form-item">
                  <label className="form-label my-3">Company Name<sup>*</sup></label>
                  <input
                    type="text"
                    className="form-control"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                  />
                </div> */}
                <div className="form-item">
                  <label className="form-label my-3">Address <sup>*</sup></label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="House Number Street Name"
                    required
                  />
                </div>
                <div className="form-item">
                  <label className="form-label my-3">Town/City<sup>*</sup></label>
                  <input
                    type="text"
                    className="form-control"
                    name="town_city"
                    value={formData.town_city}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-item">
                  <label className="form-label my-3">Country<sup>*</sup></label>
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-item">
                  <label className="form-label my-3">Postcode/Zip<sup>*</sup></label>
                  <input
                    type="text"
                    className="form-control"
                    name="postcode_zip"
                    value={formData.postcode_zip}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-item">
                  <label className="form-label my-3">Mobile<sup>*</sup></label>
                  <input
                    type="tel"
                    className="form-control"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-item">
                  <label className="form-label my-3">Email Address<sup>*</sup></label>
                  <input
                    type="email"
                    className="form-control"
                    name="email_address"
                    value={formData.email_address}
                    onChange={handleChange}
                    required
                  />
                </div>
              
                <hr />
              
                <div className="form-item">
                <label className="form-label my-3">Order Notes<sup>*</sup></label>
                  <textarea
                    name="order_notes"
                    className="form-control"
                    spellCheck="false"
                    cols="30"
                    rows="11"
                    placeholder="Order Notes (Optional)"
                    value={formData.order_notes}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-xl-5">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Products</th>
                        <th scope="col">Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item) => (
                        <tr key={item.id}>
                          <th scope="row">
                            <div className="d-flex align-items-center mt-2">
                              <img src={item.image} className="img-fluid rounded-circle" style={{ width: '90px', height: '90px' }} alt="" />
                            </div>
                          </th>
                          <td className="py-5">{item.name}</td>
                          <td className="py-5">${item.price.toFixed(2)}</td>
                          <td className="py-5">{item.quantity}</td>
                          <td className="py-5">${(item.price * item.quantity).toFixed(2)}</td>
                        </tr>
                      ))}
                      <tr>
                        <th scope="row"></th>
                        <td className="py-5"></td>
                        <td className="py-5"></td>
                        <td className="py-5">
                          <p className="mb-0 text-dark py-3">Subtotal</p>
                        </td>
                        <td className="py-5">
                          <div className="py-3 border-bottom border-top">
                            <p className="mb-0 text-dark">Rs {getCartTotal().toFixed(2)}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"></th>
                        <td className="py-5">
                          <p className="mb-0 text-dark py-4">Shipping</p>
                        </td>
                        <td colSpan="3" className="py-5">
                          <div className="form-check text-start">
                            <input type="radio" className="form-check-input bg-primary border-0" id="Shipping-1" name="shipping" value="Free Shipping" />
                            <label className="form-check-label" htmlFor="Shipping-1">Free Shipping</label>
                          </div>
                          <div className="form-check text-start">
                            <input type="radio" className="form-check-input bg-primary border-0" id="Shipping-2" name="shipping" value="Flat rate: $15.00" />
                            <label className="form-check-label" htmlFor="Shipping-2">Flat rate: Rs 15.00</label>
                          </div>
                          <div className="form-check text-start">
                            <input type="radio" className="form-check-input bg-primary border-0" id="Shipping-3" name="shipping" value="Local Pickup: $8.00" />
                            <label className="form-check-label" htmlFor="Shipping-3">Local Pickup: Rs 8.00</label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"></th>
                        <td className="py-5">
                          <p className="mb-0 text-dark text-uppercase py-3">TOTAL</p>
                        </td>
                        <td className="py-5"></td>
                        <td className="py-5"></td>
                        <td className="py-5">
                          <div className="py-3 border-bottom border-top">
                            <p className="mb-0 text-dark">Rs {(getCartTotal() + 15).toFixed(2)}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="form-check text-start">
                    <input type="checkbox" className="form-check-input bg-primary border-0" id="Terms-1" name="Terms" value="Terms" enabled disabled />
                    <label className="form-check-label" htmlFor="Terms-1">I have read and agree to the website terms and conditions <sup>*</sup></label>
                  </div>
                  <div className="form-item my-4">
                    <button className="btn btn-primary w-100 py-3" type="submit">PLACE ORDER</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Checkout;
