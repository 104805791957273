import React from 'react';
import FruitsTab from './FruitsTab'; // Import the existing FruitsTab component

const SubCategoryTab = ({ subcategories }) => {
  return (
    <div className="row g-4">
      {subcategories.map((sub) => (
        <div key={sub.id} className="col-md-6">
          <h3>{sub.label}</h3>
          <FruitsTab fruits={sub.products} category={sub.label} /> {/* Pass products to FruitsTab */}
        </div>
      ))}
    </div>
  );
};

export default SubCategoryTab;
