import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import Breadcrumb from './Breadcrumb';
import Pagination from './components/Pagination';
import useGetData from '../hooks/useGetData';
import { featuredProducts } from './components/shopData';
import { useCart } from '../context/CartContext';
import { toast } from 'react-toastify';

function Shop() {
  const title = 'Shop';
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [priceFilter, setPriceFilter] = useState(500);
  const [searchQuery, setSearchQuery] = useState('');

  const { data: categories, loading, error } = useGetData('home/categories');
  const { addToCart } = useCart();

  const getProductsFromCategories = (categories) => {
    return categories.reduce((acc, category) => {
      return acc.concat(category.products);
    }, []);
  };

  const allProducts = categories ? getProductsFromCategories(categories) : [];

  const filteredItems = selectedCategory 
    ? selectedCategory.products.filter(product => product.price <= priceFilter && product.name.toLowerCase().includes(searchQuery.toLowerCase()))
    : allProducts.filter(product => product.price <= priceFilter && product.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const currentItems = filteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory, priceFilter, searchQuery]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(selectedCategory && selectedCategory.id === category.id ? null : category);
  };

  const handlePriceFilterChange = (e) => {
    setPriceFilter(Number(e.target.value));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAddToCart = (item) => {
    addToCart(item);
    toast.success(`${item.name} has been added to the cart!`);
  };

  // Function to recursively render categories and subcategories
  const renderCategories = (categories) => {
    // Check if categories is a valid array
    if (!Array.isArray(categories)) {
      console.error('Expected categories to be an array:', categories);
      return null; // Handle the case where categories is not an array
    }
  
    return categories.map((category) => {
      // Ensure category is valid
      if (!category || !category.id || !category.label) {
        console.error('Invalid category data:', category);
        return null; // Skip invalid category
      }
  
      // Check if products and children exist and are arrays
      const hasChildren = Array.isArray(category.children) && category.children.length > 0;
      const hasProducts = Array.isArray(category.products) && category.products.length > 0;
  
      return (
        <div key={category.id}>
          <li className={`d-flex justify-content-between ${selectedCategory && selectedCategory.id === category.id ? 'active' : ''}`}>
            <button
              className="btn btn-link text-decoration-none text-dark"
              onClick={() => handleCategorySelect(category)}
            >
              {hasChildren && (
                <i className="bi bi-chevron-down"></i>
              )}
              {category.label}
            </button>
            <span>({hasProducts ? category.products.length : 0})</span>
          </li>
          {selectedCategory && selectedCategory.id === category.id && hasChildren && (
            <ul className="list-unstyled ps-3">
              {renderCategories(category.children)} {/* Recursive call for subcategories */}
            </ul>
          )}
        </div>
      );
    });
  };
  

  return (
    <>
      <Navbar />
      <Breadcrumb title={title} />
      <div className="container-fluid py-5">
        <div className="container">
          <h1 className="mb-4">Fresh Fruits Shop</h1>
          <div className="row g-4">
            <div className="col-lg-3">
              <div className="mb-4">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search for products..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              {loading && <p>Loading categories...</p>}
              {error && <p>Error loading categories</p>}
              {!loading && !error && (
                <div className="mb-4">
                  <h4>Categories</h4>
                  <ul className="list-unstyled">
                    <li className={`d-flex justify-content-between ${!selectedCategory ? 'active' : ''}`}>
                      <button
                        className="btn btn-link text-decoration-none text-dark"
                        onClick={() => setSelectedCategory(null)}
                      >
                        Show All
                      </button>
                    </li>
                    {renderCategories(categories)} {/* Render categories and subcategories */}
                  </ul>
                </div>
              )}
              <div className="mb-4">
                <h4>Filter by Price</h4>
                <input
                  type="range"
                  className="form-range"
                  min="0"
                  max="500"
                  value={priceFilter}
                  id="priceRange"
                  onChange={handlePriceFilterChange}
                />
                <div className="d-flex justify-content-between">
                  <span>Rs 0</span>
                  <span id="priceValue">Rs {priceFilter}</span>
                </div>
              </div>
              <div className="mb-4">
                <h4>Featured Products</h4>
                {featuredProducts.map((product, index) => (
                  <div key={index} className="d-flex mb-3">
                    <img src={product.img} alt={product.name} className="me-3" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                    <div>
                      <h6>{product.name}</h6>
                      <div className="d-flex">
                        {Array.from({ length: 5 }, (_, i) => (
                          <i key={i} className="fa fa-star text-warning"></i>
                        ))}
                      </div>
                      <p className="mb-0 text-primary">Rs {product.price}</p>
                      {product.oldPrice && <small><del>Rs {product.oldPrice}</del></small>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row g-4">
                {currentItems.map((item, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="card h-100 text-center">
                      <img src={item.image} className="card-img-top" alt={item.name} />
                      <div className="card-body">
                        <h5 className="card-title">{item.name}</h5>
                        <p className="card-text text-primary">Rs {item.price}</p>
                      </div>
                      <div className="card-footer d-flex justify-content-around">
                        <button
                          onClick={() => handleAddToCart(item)}
                          className="btn btn-primary"
                        >
                          <i className="bi bi-cart"></i> Add to Cart
                        </button>
                        <Link to={`/shop/product/${item.id}`} className="btn btn-secondary">
                          <i className="bi bi-eye"></i> View
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Shop;
