
// OrderItem.js
import React from 'react';
import styles from './OrderItem.module.scss';

const OrderItem = ({ order }) => {
    const { order_status, total, form_data } = order;
    const { first_name, last_name, company_name, address, town_city, country, postcode_zip, mobile, email_address, cart_items } = form_data;

    return (
        <div className={styles.orderItem}>
            <div className={styles.orderDetails}>
                <h2>Order Details</h2>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Order Status</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{order.id}</td>
                            <td>{order_status}</td>
                            <td>${total.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={styles.billingDetails}>
                <h2>Billing Details</h2>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Company</th>
                            <th>Address</th>
                            <th>Town/City</th>
                            <th>Country</th>
                            <th>Postcode/ZIP</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>Order Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{first_name}</td>
                            <td>{last_name}</td>
                            <td>{company_name}</td>
                            <td>{address}</td>
                            <td>{town_city}</td>
                            <td>{country}</td>
                            <td>{postcode_zip}</td>
                            <td>{mobile}</td>
                            <td>{email_address}</td>
                            <td>{form_data.order_notes || '-'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={styles.cartItems}>
                <h2>Cart Items</h2>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cart_items.map(item => (
                            <tr key={item.id}>
                                <td>{item.product_name || 'N/A'}</td>
                                <td>Rs {item.price.toFixed(2)}</td>
                                <td>{item.quantity}</td>
                                <td>Rs {(item.price * item.quantity).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderItem;
