import React from "react";
import { useCart } from "../../context/CartContext";
import { toast } from "react-toastify";
 // Adjust the path based on your project structure

const ProductCard = ({ product }) => {
  const { image, name, price, rating } = product;
  const { addToCart } = useCart(); // Assuming addToCart function is provided by CartContext

  const stars = Array(5)
    .fill()
    .map((_, i) => (
      <i
        key={i}
        className={`fas fa-star ${i < Math.floor(rating) ? "text-primary" : ""}`}
      />
    ));

  const handleAddToCart = () => {
    addToCart(product); // Add current product to the cart
    // Optionally, you can show a confirmation or update UI to indicate the product has been added to cart
    // alert(`${name} added to cart!`);
    toast.success(`${name} added to cart!`);
  };

  return (
    <div className="col-lg-6 col-xl-4">
      <div className="p-4 rounded bg-light">
        <div className="row align-items-center">
          <div className="col-6">
            <img
              src={image}
              className="img-fluid rounded-circle w-100"
              alt={name}
            />
          </div>
          <div className="col-6">
            <a href="/product/1" className="h5">
              {name}
            </a>
            <div className="d-flex my-3">{stars}</div>
            <h4 className="mb-3">Rs {price}</h4>
            <button
              onClick={handleAddToCart}
              className="btn border border-secondary rounded-pill px-3 text-primary"
            >
              <i className="fa fa-shopping-bag me-2 text-primary"></i> Add to cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
