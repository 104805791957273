import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaPlus, FaMinus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import useGetData from '../../../hooks/useGetData'; // Adjust the path as needed
import AddCategoryForm from '../AddCategoryForm'; // Adjust the path as needed
import { deleteData, putData } from '../../../redux/apiUtils'; // Assume this is a utility for API calls
import { StyledButton } from '../../CustomStyles';
import { Modal, Button, Spinner } from 'reactstrap'; // Add Modal for confirmation
import './CategoryInput.scss'; // Adjust the path as needed

function CategoryInput() {
  const { data: categories = [], loading, refetch, postData } = useGetData('home/categories');
  const [items, setItems] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [expandedItems, setExpandedItems] = useState(new Set());
  const [isEditing, setIsEditing] = useState(null);
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [activeSubcategoryId, setActiveSubcategoryId] = useState(null);
  const [deleteModal, setDeleteModal] = useState({ show: false, id: null, isSubcategory: false });

  useEffect(() => {
    if (categories) {
      const buildHierarchy = (categories) => {
        const categoryMap = new Map();
        const roots = [];

        categories.forEach((cat) => {
          categoryMap.set(cat.id, { ...cat, subcategories: [] });
        });

        categoryMap.forEach((cat) => {
          if (cat.parent_id) {
            const parent = categoryMap.get(cat.parent_id);
            if (parent) parent.subcategories.push(cat);
          } else {
            roots.push(cat);
          }
        });

        return roots;
      };

      const hierarchicalCategories = buildHierarchy(categories);
      setItems(hierarchicalCategories);
      setParentCategories(categories.filter(cat => !cat.parent_id));
    }
  }, [categories]);

  // const handleChange = (id, field, value) => {
  //   setItems(prevItems =>
  //     prevItems.map(item =>
  //       item.id === id ? { ...item, [field]: value } : item
  //     )
  //   );
  // };
  const handleChange = (id, field, value, isSubcategory = false) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === id && !isSubcategory
          ? { ...item, [field]: value }
          : {
              ...item,
              subcategories: item.subcategories.map(subcat =>
                subcat.id === id && isSubcategory
                  ? { ...subcat, [field]: value }
                  : subcat
              )
            }
      )
    );
  };

  const handleAddCategory = async (newCategory) => {
    try {
      await postData(newCategory);
      toast.success('Category added successfully');
      refetch();
    } catch (error) {
      toast.error('Failed to add category');
    }
  };

  const handleUpdateCategory = async (categoryId, updatedData) => {
    try {
      await putData(`home/categories/subcategory/${categoryId}`, updatedData); // API call for updating category
      toast.success('Category updated successfully');
      setIsEditing(null);
      refetch();
    } catch (error) {
      toast.error('Failed to update category');
    }
  };

  const handleDeleteCategory = async () => {
    const { id, isSubcategory } = deleteModal;
    try {
      await deleteData('home/categories', id);
      toast.success(`${isSubcategory ? 'Subcategory' : 'Category'} deleted successfully`);
      setDeleteModal({ show: false, id: null, isSubcategory: false });
      refetch();
    } catch (error) {
      toast.error('Failed to delete category');
    }
  };

  const toggleExpand = (id) => {
    setExpandedItems((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(id)) {
        newExpanded.delete(id);
        setActiveCategoryId(null);
        setActiveSubcategoryId(null);
      } else {
        newExpanded.add(id);
        setActiveCategoryId(id);
      }
      return newExpanded;
    });
  };

  const renderCategories = (categories, level = 0) => {
    return categories.map((item) => {
      const productString = item.products.map(
        (product) => `${product.name} - ${product.price}`
      ).join(', ');

      return (
        <div key={item.id} className={`category-item level-${level} ${activeCategoryId === item.id ? 'active' : ''}`}>
          <div className="category-row">
            <span
              className={`toggle-icon ${expandedItems.has(item.id) ? 'rotate' : ''}`}
              onClick={() => toggleExpand(item.id)}
            >
              {item.subcategories.length > 0 && (
                expandedItems.has(item.id) ? <FaMinus /> : <FaPlus />
              )}
            </span>

            {isEditing === item.id ? (
              <input
                type="text"
                className="form-control inline-edit"
                value={item.label}
                onChange={(e) => handleChange(item.id, 'label', e.target.value)}
              />
            ) : (
              <span className="category-label">{item.label}</span>
            )}

            <span className="category-actions">
              {isEditing === item.id ? (
                <StyledButton
                  onClick={() => handleUpdateCategory(item.id, { label: item.label })}
                  className="save-button"
                >
                  Save
                </StyledButton>
              ) : (
                <StyledButton onClick={() => setIsEditing(item.id)}>
                  <FaEdit />
                </StyledButton>
              )}

              <StyledButton
                className="delete-button"
                onClick={() => setDeleteModal({ show: true, id: item.id, isSubcategory: false })}
              >
                <FaTrashAlt />
              </StyledButton>
            </span>
          </div>

          {expandedItems.has(item.id) && item.products && item.products.length > 0 && (
          <div className="product-display">
            <textarea
              className="products-textarea"
              value={productString}
              readOnly
            />
          </div>
        )}


          {expandedItems.has(item.id) && item.subcategories.length > 0 && (
            <div className="subcategory-container">
              <h2>Sub Category</h2>
              {renderSubcategories(item.subcategories, level + 1)}
            </div>
          )}
        </div>
      );
    });
  };
  const renderSubcategories = (subcategories, level = 1) => {
    console.log("Rendering subcategories at level:", level, subcategories);
    
    return subcategories.map((subcat) => {
      const subcategoryProductString = subcat.products.map(
        (product) => `${product.name} - ${product.price}`
      ).join(', ');
  
      return (
        <div key={subcat.id} className={`subcategory-item level-${level} ${activeSubcategoryId === subcat.id ? 'active' : ''}`}>
          <div className="subcategory-row">
            <span className={`toggle-icon ${expandedItems.has(subcat.id) ? 'rotate' : ''}`} onClick={() => toggleExpand(subcat.id)}>
              {subcat.subcategories.length > 0 && (
                expandedItems.has(subcat.id) ? <FaMinus /> : <FaPlus />
              )}
            </span>
  
            {isEditing && isEditing.id === subcat.id ? (
              <input
                type="text"
                className="form-control inline-edit"
                value={subcat.label}
                onChange={(e) => handleChange(subcat.id, 'label', e.target.value, true)}
              />
            ) : (
              <span className="subcategory-label">{subcat.label}</span>
            )}
  
            <span className="subcategory-actions">
              {isEditing && isEditing.id === subcat.id ? (
                <StyledButton
                  onClick={() => handleUpdateCategory(subcat.id, { label: subcat.label }, true)}
                  className="save-button"
                >
                  Save
                </StyledButton>
              ) : (
                <StyledButton onClick={() => setIsEditing({ id: subcat.id, isSubcategory: true })}>
                  <FaEdit />
                </StyledButton>
              )}
  
              <StyledButton
                className="delete-button"
                onClick={() => setDeleteModal({ show: true, id: subcat.id, isSubcategory: true })}
              >
                <FaTrashAlt />
              </StyledButton>
            </span>
          </div>
  
          {subcat.products && subcat.products.length > 0 && (
            <textarea
              className="products-textarea"
              value={subcategoryProductString}
              readOnly
            />
          )}
  
          {expandedItems.has(subcat.id) && subcat.subcategories.length > 0 && (
            <div className="nested-subcategory-container">
              {renderSubcategories(subcat.subcategories, level + 1)}
            </div>
          )}
        </div>
      );
    });
  };
  
  

  return (
    <div className="category-table-container">
      <h1>Category Tree</h1>


     <AddCategoryForm onSubmit={handleAddCategory} parentCategories={parentCategories} />
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="category-list">
          {renderCategories(items)}
        </div>
      )}


      
      {/* Delete Modal */}
      <Modal isOpen={deleteModal.show} toggle={() => setDeleteModal({ ...deleteModal, show: false })}>
        <div className="modal-header">
          <h5 className="modal-title">Confirm Delete</h5>
          <Button close onClick={() => setDeleteModal({ ...deleteModal, show: false })} />
        </div>
        <div className="modal-body">
          <p>Are you sure you want to delete this {deleteModal.isSubcategory ? 'subcategory' : 'category'}?</p>
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={() => setDeleteModal({ ...deleteModal, show: false })}>Cancel</Button>
          <Button color="danger" onClick={handleDeleteCategory}>Delete</Button>
        </div>
      </Modal>
    </div>
  );
}

export default CategoryInput;
