import React from 'react';
import Tabs from './Tabs'; // Adjust the path as needed
import FeatureInput from './FeatureInput';
import ServicesInput from './ServicesInput';
import ProductsInput from './ProductsInput';
import VegetableInput from './VegetableInput';
import TestimonialsInput from './TestimonialsInput';
import CountersInput from './CoutersInput';
import CategoryInventory from './CategoryInventory';
import CategoryInput from './CategoryInput';
import AttributesManager from './AttributesManager';

function TabsGroup({ featureItems, services, products }) {
  const tabs = {
    'Feature Items': <FeatureInput featureItems={featureItems} />,
    'Category Items': <CategoryInventory  />,
    'Services Input': <ServicesInput services={services} />,
    'Products Input': <ProductsInput products={products} />,
    'Vegetables Input': <VegetableInput  />,
    'Testimonials Input': <TestimonialsInput  />,
    'Couters Input': <CountersInput  />,
    'Category List': <CategoryInput  />,
    'Attribute List': <AttributesManager  />,
  };

  return (
    <div className="container-fluid py-5">
    <div className="app-container">
      <Tabs tabs={tabs} />
    </div>
    </div>
  );
}

export default TabsGroup;
