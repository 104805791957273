// src/components/CategoryGroup.js

import React from 'react';
import useGetData from '../../hooks/useGetData'; // Import your custom hook
import CategoryList from './CategoryList'; // Import the CategoryList component

function CategoryGroup() {
  const { data: categories, loading, error } = useGetData('home/homecategories'); // Fetch categories

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="container-fluid py-5">
      <div className="container py-5">
        <CategoryList categories={categories} />
      </div>
    </div>
  );
}

export default CategoryGroup;
