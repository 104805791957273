import React, { useState } from 'react';
import './AddCategoryForm.scss'; // Import the new styles

function AddCategoryForm({ onSubmit, parentCategories = [] }) { // Default to an empty array
  const [newCategory, setNewCategory] = useState({ label: '', parentId: '' });

  // Handle changes to the form fields
  const handleChange = (field, value) => {
    setNewCategory(prevCategory => ({
      ...prevCategory,
      [field]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting:", newCategory);
    if (onSubmit) {
      onSubmit(newCategory);
      setNewCategory({ label: '', parentId: '' });
    }
  };
  

  return (
    <form onSubmit={handleSubmit} className="add-category-form">
      <div className="form-row">
        <input
          type="text"
          className="form-control"
          placeholder="New Category Label"
          value={newCategory.label}
          onChange={(e) => handleChange('label', e.target.value)}
          required
        />
        <select
          className="form-control"
          value={newCategory.parentId}
          onChange={(e) => handleChange('parentId', e.target.value)}
        >
          <option value="">None (Top-level)</option>
          {parentCategories.length > 0 ? ( // Check if parentCategories has elements
            parentCategories.map(cat => (
              <option key={cat.id} value={cat.id}>
                {cat.label}
              </option>
            ))
          ) : (
            <option disabled>No Parent Categories Available</option> // Fallback option if empty
          )}
        </select>
      </div>
      <button type="submit" className="submit-button">Add Category</button>
    </form>
  );
}

export default AddCategoryForm;
