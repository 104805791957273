import React, { useState } from 'react';
import Box from '../components/box/Box';
import DashboardWrapper, { DashboardWrapperMain, DashboardWrapperRight } from '../components/dashboard-wrapper/DashboardWrapper';
import { Container, Col, Form, FormGroup, Label } from 'reactstrap';
import useGetData from '../hooks/useGetData';
import { toast } from 'react-hot-toast';
import { Pagination } from '@mui/material';
import Loader from '../components/Loader/Loader';
import { StyledButton, StyledInput, StyledTable, StyledSelect, TableWrapper } from './CustomStyles';
import { deleteData, postData, putData } from '../redux/apiUtils';

const Products = () => {
    const [enterTitle, setEnterTitle] = useState('');
    const [enterDescription, setEnterDescription] = useState('');
    const [enterCategory, setEnterCategory] = useState('');
    const [enterPrice, setEnterPrice] = useState('');
    const [enterDiscount, setEnterDiscount] = useState('');
    const [enterProductImg, setEnterProductImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editProductId, setEditProductId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedAttributes, setSelectedAttributes] = useState({}); // New state for selected attributes

    const { data: productsData, refetch } = useGetData('products');
    const { data: categoryList } = useGetData('products/categories');
    const { data: attributesList } = useGetData('products/attributes'); // Assuming there's an endpoint for attributes
    const [page, setPage] = useState(1);
    const productsPerPage = 5;

    const filteredProducts = productsData?.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.category.label.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

    const totalProducts = filteredProducts.length;
    const totalPages = Math.ceil(totalProducts / productsPerPage);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const startIndex = (page - 1) * productsPerPage;
    const endIndex = Math.min(startIndex + productsPerPage, totalProducts);

    const deleteProduct = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this product?');
        if (confirmed) {
            try {
                await deleteData(`products`, id);
                toast.success('Product Deleted');
                refetch();
            } catch (error) {
                console.error('Error deleting product:', error);
                toast.error('Failed to delete product');
            }
        }
    };

    const addProduct = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('name', enterTitle);
            formData.append('description', enterDescription);
            formData.append('price', enterPrice);
            formData.append('category', enterCategory);
            formData.append('discount', enterDiscount);
            formData.append('image', enterProductImg);
            // Add attributes to formData
            for (const [key, value] of Object.entries(selectedAttributes)) {
                formData.append(`attributes[${key}]`, value);
            }

            const response = await postData('products', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response) {
                setLoading(false);
                toast.success('Product successfully added!');
                resetForm();
                refetch();
            } else {
                setLoading(false);
                toast.warn('Product not added');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error adding product:', error);
            toast.warn('Product not added');
        }
    };

    const updateProduct = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log('Editing product with ID:', editProductId);

        if (!editProductId || typeof editProductId !== 'string') {
            console.error('Invalid product ID:', editProductId);
            setLoading(false);
            return toast.error('Invalid product ID');
        }

        try {
            const formData = new FormData();
            formData.append('name', enterTitle);
            formData.append('description', enterDescription);
            formData.append('price', parseFloat(enterPrice));
            formData.append('category', enterCategory);
            formData.append('discount', enterDiscount);
            if (enterProductImg) {
                formData.append('image', enterProductImg);
            }
            // Add attributes to formData
            for (const [key, value] of Object.entries(selectedAttributes)) {
                formData.append(`attributes[${key}]`, value);
            }

            const response = await putData(`products/${editProductId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response) {
                setLoading(false);
                toast.success('Product successfully updated!');
                resetForm();
                refetch();
                setIsEditing(false);
            } else {
                setLoading(false);
                toast.warn('Product not updated');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error updating product:', error);
            toast.warn('Product not updated');
        }
    };

    const editProduct = (product) => {
        setEnterTitle(product.name);
        setEnterDescription(product.description);
        setEnterPrice(product.price);
        setEnterCategory(product.category.label);
        setEnterDiscount(product.discount || 0);
        setEditProductId(product.id);
        setSelectedAttributes(product.attributes.reduce((acc, attr) => {
            acc[attr.attribute_name] = attr.attribute_values[0]; // Default to first value
            return acc;
        }, {}));
        setIsEditing(true);
    };

    const resetForm = () => {
        setEnterTitle('');
        setEnterDescription('');
        setEnterPrice('');
        setEnterCategory('');
        setEnterDiscount('');
        setEnterProductImg(null);
        setEditProductId(null);
        setSelectedAttributes({}); // Reset attributes
        setIsEditing(false);
    };

    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <Box>
                    <div className="mb">
                        <section>
                            <Container>
                                {/* Search Input */}
                                <FormGroup>
                                    <Label for="search">Search Products</Label>
                                    <StyledInput
                                        type="text"
                                        id="search"
                                        placeholder="Search by title or category"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </FormGroup>
                                <TableWrapper>
                                    <StyledTable className='table'>
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Title</th>
                                                <th>Category</th>
                                                <th>Price</th>
                                                <th>Discount</th>
                                                <th>Attributes</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan="6" className="text-center"><Loader /></td>
                                                </tr>
                                            ) : (
                                                filteredProducts.slice(startIndex, endIndex).map(item => (
                                                    <tr key={item?.id}>
                                                        <td>
                                                        {item?.image && (
                                                            item.image.startsWith('http://') || item.image.startsWith('https://') ? (
                                                                <img style={{ width: '100px' }} src={`${item.image}`} alt='' />
                                                            ) : (
                                                                <img style={{ width: '100px' }} src={`${process.env.PUBLIC_URL}/${item.image}`} alt='' />
                                                            )
                                                            )}
                                                        </td>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.category.label}</td>
                                                        <td>Rs {item?.price}</td>
                                                        <td>Rs {item?.discount || 0}</td>
                                                        <td>
                                                            {item?.attributes.map(attr => (
                                                                <div key={attr.id}>
                                                                    {attr.attribute_name}: {attr.attribute_values.join(', ')}
                                                                </div>
                                                            ))}
                                                        </td>
                                                        <td>
                                                            <StyledButton onClick={() => editProduct(item)} className="btn btn-primary" style={{marginRight:'20px'}}>Edit</StyledButton>
                                                            <StyledButton onClick={() => deleteProduct(item?.id)} className="btn btn-danger">Delete</StyledButton>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </StyledTable>
                                </TableWrapper>

                                <Col lg='12' className='text-center'>
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                        color="primary"
                                    />
                                </Col>
                                <DashboardWrapperRight>
                                <Form onSubmit={isEditing ? updateProduct : addProduct}>
                                    <h2>{isEditing ? 'Edit Product' : 'Add Product'}</h2>
                                    <FormGroup>
                                        <Label for="title">Product Title</Label>
                                        <StyledInput
                                            type="text"
                                            id="title"
                                            value={enterTitle}
                                            onChange={(e) => setEnterTitle(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="description">Description</Label>
                                        <StyledInput
                                            type="textarea"
                                            id="description"
                                            value={enterDescription}
                                            onChange={(e) => setEnterDescription(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="category">Category</Label>
                                        <StyledSelect
                                            id="category"
                                            value={enterCategory}
                                            onChange={(e) => setEnterCategory(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Category</option>
                                            {categoryList?.map(category => (
                                                <option key={category.id} value={category.label}>{category.label}</option>
                                            ))}
                                        </StyledSelect>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="price">Price</Label>
                                        <StyledInput
                                            type="number"
                                            id="price"
                                            value={enterPrice}
                                            onChange={(e) => setEnterPrice(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="discount">Discount</Label>
                                        <StyledInput
                                            type="number"
                                            id="discount"
                                            value={enterDiscount}
                                            onChange={(e) => setEnterDiscount(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="image">Product Image</Label>
                                        <StyledInput
                                            type="file"
                                            id="image"
                                            onChange={(e) => setEnterProductImg(e.target.files[0])}
                                            accept="image/*"
                                        />
                                    </FormGroup>
                                    {/* Attribute Selection */}
                                    {attributesList?.map(attribute => (
                                        <FormGroup key={attribute.id}>
                                            <Label for={`attribute-${attribute.id}`}>{attribute.attribute_name}</Label>
                                            <StyledInput
                                                id={`attribute-${attribute.id}`}
                                                type="text"
                                                value={selectedAttributes[attribute.attribute_name] || ''}
                                                onChange={(e) => setSelectedAttributes(prev => ({ ...prev, [attribute.attribute_name]: e.target.value }))}
                                                placeholder={`Enter ${attribute.attribute_name}`} // Optional placeholder
                                            />
                                        </FormGroup>
                                    ))}

                                    <StyledButton type="submit" className="btn btn-primary">{isEditing ? 'Update Product' : 'Add Product'}</StyledButton>
                                </Form>
                                </DashboardWrapperRight>
                            </Container>
                        </section>
                    </div>
                </Box>
               
            </DashboardWrapperMain>
           
        </DashboardWrapper>
    );
};

export default Products;
