import React from 'react';
import { useCart } from '../../context/CartContext'; // Adjust the path based on your project structure
import './styles.scss'; // Import the SCSS file
import { toast } from 'react-toastify';
const VegetableItem = ({ image, link, bgClass, title, description, price }) => {
  const { addToCart } = useCart(); // Assuming addToCart function is provided by CartContext

  const handleAddToCart = () => {
    const item = {
      image,
      title,
      description,
      price,
    };
    addToCart(item); // Add the item to the cart
    // Optionally, you can show a confirmation or update UI to indicate the item has been added to cart
    //alert(`${title} added to cart!`);
    toast.success(`${title} added to cart!`);
  };

  return (
    <div className="border border-primary rounded position-relative vegetable-item">
      <div className="vegetable-img">
        <img src={image} className="img-fluid w-100 rounded-top" alt={title} />
      </div>
      <div className={`text-white ${bgClass} px-3 py-1 rounded position-absolute`} style={{ top: '10px', right: '10px' }}>
        Vegetable
      </div>
      <div className="p-4 rounded-bottom">
        <h4>{title}</h4>
        <p>{description}</p>
        <div className="d-flex justify-content-between flex-lg-wrap">
          <p className="text-dark fs-5 fw-bold mb-0">Rs {price.toFixed(2)}</p>
          <button onClick={handleAddToCart} className="btn border border-secondary rounded-pill px-3 text-primary">
            <i className="fa fa-shopping-bag me-2 text-primary"></i> Add to cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default VegetableItem;
