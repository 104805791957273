import React from 'react';
import { useCart } from '../../context/CartContext'; // Adjust the relative path based on your project structure
import { toast } from 'react-toastify';

const FruitsTab = ({ fruits , category}) => {
  const { addToCart } = useCart();

  //console.log(category);

  const handleAddToCart = (fruit) => {
    addToCart(fruit);
    //alert(`${fruit.name} added to cart!`);
    toast.success(`${fruit.name} added to cart!`);
  };

  return (
    <div className="row g-4">
      {fruits.map((fruit) => (
        <div key={fruit.id} className="col-md-6 col-lg-4 col-xl-3">
          <div className="rounded position-relative fruite-item">
            <div className="fruite-img">
              <img src={fruit.image} className="img-fluid w-100 rounded-top" alt={fruit.name} />
            </div>
            <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>
              {category}
            </div>
            <div className="p-4 border border-secondary border-top-0 rounded-bottom">
              <h4>{fruit.name}</h4>
              <p>{fruit.description}</p>
              <div className="d-flex justify-content-between flex-lg-wrap">
                <p className="text-dark fs-5 fw-bold mb-0">Rs {fruit.price} / kg</p>
                <button onClick={() => handleAddToCart(fruit)} className="btn btn-primary">
                  <i className="fa fa-shopping-bag me-2"></i> Add to cart
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FruitsTab;
