import React from 'react';
import SubCategoryTab from './SubCategoryTab'; // Import the SubCategoryTab component
import FruitsTab from './FruitsTab'; // Import the FruitsTab component to display parent category products

function CategoryList({ categories }) {
  return (
    <div className="tab-class text-center">
      <div className="row g-4">
        <div className="col-lg-4 text-start">
          <h1>Our Organic Products</h1>
        </div>
        <div className="col-lg-8 text-end">
          <ul className="nav nav-pills d-inline-flex text-center mb-5">
            {categories.map((category, index) => (
              <li className="nav-item" key={category.id}>
                <a className="d-flex m-2 py-2 bg-light rounded-pill" data-bs-toggle="pill" href={`#tab-${index}`}>
                  <span className="text-dark" style={{ width: '130px' }}>{category.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="tab-content">
        {categories.map((category, index) => (
          <div
            id={`tab-${index}`}
            className={`tab-pane fade p-0 ${index === 0 ? 'show active' : ''}`}
            key={category.id}
          >
            {/* Display products of the parent category */}
            <FruitsTab fruits={category.products} category={category.label} />

            {/* Display subcategories */}
            <SubCategoryTab subcategories={category.children} /> {/* Use childCategories from HomeCategory */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategoryList;
